export const DATA = [
  { title: "오디오 가이드", url: "/audio" },
  {
    title: "텔레비전에 내가 나왔으면",
    url: "/television",
  },
  { title: "싯가 현재가격", url: "/shitga-current-price" },

  { title: "포트폴리오", url: "/portfolio" },
];
