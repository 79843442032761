import { CSSProperties } from "react";

export const snowfallBaseStyle: CSSProperties = {
  pointerEvents: "none",
  backgroundColor: "transparent",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

export const targetFrameTime = 1000 / 60;
