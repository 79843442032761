import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const PitchAdjuster = styled.input``;

export const Speaker = styled.div`
  width: 3rem;
  height: 3rem;
  background: black;
`;
