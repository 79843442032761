export const EXPLANATION = [
  {
    title: "작품 설명",
    description: `텔레비전에 내가 나왔으면 정말 좋겠네 정말 좋겠네의 모티브는 작가가 유년 시절 즐겨 들었던 동요에서 유래합니다. 시청 가구수에 비해 한정된 채널을 가지고 있는 텔레비전에 등장한다는 것은, 그 자체로 대단한 이목을 끄는 중대한 사건이었습니다. 예컨대 텔레비전이라는 매체가 가졌던 아우라는 시청 수요보다 턱없이 적었던 스크린 공급에서 유래했습니다.
시간이 지나고 빠른 디지털화와 함께 2022년의 우리는 더 많은 스크린 속에서 살아갑니다. 사용자가 직접 콘텐츠를 손쉽게 업로드할 수 있는 Web 2.0의 시대 속에서, 인스타그램, 유튜브에는 하루에도 끊임없이 수많은 콘텐츠가 올라옵니다. 이제 텔레비전으로 상징되는 디지털 스크린에 내 모습이 투영되는 일은 더는 ‘정말 좋겠네’ 할 만큼 특별한 일이 아닙니다. 이전의 우리는 텔레비전이라는 스크린에 투사될 경우 줄곧 좋아했지만, 오늘날의 우리는 내 스크린의 파급력을 확인하는 지표인 조회수와 좋아요를 확인하기에 급급합니다.
청년미술상점에서 이번에 선보이는 참여형 예술은 수많은 콘텐츠의 범람을 이끌었던 Web 2.0기술을 그대로 사용하지만, 인위적으로 스크린 노출 횟수를 5분에 1회로 제한하여 이전에 텔레비전이 가졌던 아우라를 복구하고자 합니다. 예컨대 조회수, 좋아요라는 양적 가치에 집중하지 않고, 그저 자신의 사진이 스크린에 투영되었다는 그 사실 만으로 충분히 좋아할 수 있는 순수한 동심을 되찾고자 합니다.
그러나 스크린에 투영되는 이미지는 참여자가 업로드한 사진이 그대로 노출되지 않습니다. 사용자의 사진을 픽셀화되고, 그 픽셀화된 이미지들은 다양한 회전과 왜곡의 과정을 거칩니다. 이는 오래된 매체인 텔레비전은 물론이고, 새로운 스크린인 Web 2.0 기반 플랫폼들 모두에 투영되는 우리의 이미지는 실제의 이미지가 아닌 변환되고 왜곡된 이미지임을 상징합니다.`,
  },
  {
    title: "참여 방법",
    description: `텔레비전에 내가 나왔으면 정말 좋겠네 정말 좋겠네는 여러분들이 올려주신 사진을 바탕으로 하는 참여형 예술입니다.
    참여 방법은 다음과 같습니다.
    1. 참여하기 버튼을 클릭합니다.
    2. 텔레비전(청년미술상점 내 모니터)에 송출하고 싶은 사진을 업로드해주세요.
    3. 개인 식별을 위한 닉네임을 입력합니다.
    4. 여러분의 사진이 성공적으로 등록되었습니다. 순서가 되면 올려주신 사진이 청년미술상점 내 모니터에 송출됩니다.`,
  },
];
