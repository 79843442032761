export const AUDIO_DATA = [
  {
    idx: 0,
    title: "싯가 소개",
    audio: "https://laboratory-occupied.com/assets/audio/Shitga.mp3",
    links: {
      description: "싯가 현재가격 보러가기",
      url: "https://laboratory-occupied.com/shitga-current-price",
    },
    description: `벽에 25개의 작품이 걸려 있습니다. 각 작품의 가격은 판매되는 순간 싯가로 결정되는데, 직전에 팔린 두 작품의 가격의 합으로 추산됩니다. 첫 번째로 판매되는 작품의 가격은 1597원이지만, 열번째로 판매되는 작품의 가격은 약 12만원, 스무번째로 판매되는 작품은 1493만원이 됩니다. 한 연작의 작품 가격이 많게는 몇십만배까지 차이나게 되는 것입니다.
    짐작하셨겠지만, 이번 청년 미술상점에서는 전시된 캔버스 25개만이 작품이 아니라, 판매 방식 또한 작품을 구성하고 있습니다. 예컨대 캔버스 작품들이 싯가로 판매되고 거래되는 방식 그 자체가 예술입니다. 이 판매행위예술은 지난 2년간 NFT, 코인 시장, 주식 시장에서 나타났던 과열된 금융시장의 모습을 대변하고 있습니다.
    싯가는 실체가 없고 실물경제와 연관되지 않았음에도 급등하는 가격, 그리고 이 급등세를 따라 더욱 금융화되는 재화들에 대해 다루고 있습니다. 모니터에 띄워진 보이지 않는 손은 이러한 급등세가 시장 논리의 결과물이었음을 강조합니다.
    이 행위예술작품은 팬데믹 이후 과열된 금융시장을 다룬다는 점에서 사회적이지만, 동시에 대학에 갓 입학한 사회초년생인 작가가 직접 경험한 외상이자 충격을 바탕으로 한다는 점에서 자전적입니다. 그러나 이 외상은 무조건적으로 부정되기보다는 그 자체로 긍정되며 예술적인 형태, 또 참여의 형태로 부활합니다.`,
  },
  {
    idx: 1,
    title: "작가 소개",
    audio: "https://laboratory-occupied.com/assets/audio/Author.mp3",
    links: {
      description: "페스티벌 메신저 바로가기",
      url: "https://festival-messenger.com",
    },
    description: `과학고를 졸업하고 산업공학을 전공한 최정윤 작가는 공학 특유의 데카르트-합리주의식 접근법에 회의적이었습니다. 모든 자연, 사회적 현상의 바탕에는 특정한 이유가 있으며, 이 이유는 ‘명석 판명한 관념’들의 종합으로 이루어질 수 있다는 공학적 시각은, 실제로는 코로나 팬데믹 등 설명 불가한 사건들이 매일같이 일어나는 현실과는 괴리가 있어 보였습니다.
        더구나 이런 시도들은, 도스토예프스키의 주장대로, 그 속에서 침을 뱉을 수도 없는 아름답지만 답답한 수정궁을 건설하는 일처럼 다가왔습니다. 예컨대 현대의 인류는 과학주의라는 새로운 종교를 맞이하게 되지는 않았는지 최정윤 작가는 되물었습니다.
        따라서 컴퓨터를 이용하는 작가의 작업물들은 공학의 산물을 탈공학적인 관점으로 바라보고, 그 공학적 산물의 가능성을 넓혀나가는 데에 초점을 두고 있습니다. 예컨대 합리주의의 담론보다 아름다움의 담론이 더 넓은 범주를 포괄하고 있고, 후자가 인류가 나아가야할 방향성임을 작가는 주장합니다. 이러한 관점 속에서 최정윤 작가의 작업은 크게 세 부류로 구분될 수 있습니다.
        첫 번째 부류는 컴퓨터의 반복성과 임의성을 이용한 웹드로잉 작업들입니다. Laboratory Occupied 시리즈가 이 작업의 대표적인 결과물인데, 청년미술상점내 테이블 위의 40여종의 엽서에서 직접 확인하실 수 있습니다.
        두 번째 부류는 웹으로 대표되는 현대적인 미디어의 가능성을 넓혀나가는 작업들입니다. 대표적인 작업 Festival Messenger는 지정된 위치에서만 읽을 수 있는 위치기반 메신저입니다. festival-messenger.com에서 직접 작품을 체험하실 수 있습니다.
        세 번째 부류는 합목적성에 대한 의문을 제기하는 작업들입니다. 이번 청년미술상점의 판매행위예술 싯가가 대표적인 예시입니다. 싯가에서 보실 수 있는 것과 같이, 코인이나 NFT, 주식 등 금융자산에 대한 현대인들의 집착을 예술의 형태로 옮겨놓아, 우리가 그동안 합목적적으로 접근했던 여러 다양한 사회 현상들에 대해 되돌아볼 기회를 제공합니다.`,
  },
  {
    idx: 2,
    title: "기법 소개",
    audio: "https://laboratory-occupied.com/assets/audio/Technique.mp3",
    links: {
      description: "Laboratory Occupied 작품보기",
      url: "https://laboratory-occupied.com/lobby",
    },
    description: `이번 청년미술상점 싯가에서 판매하는 25개의 연작은 컴퓨터 프로그래밍을 통해 만들어졌습니다. 최정윤 작가의 웹드로잉은 웹사이트를 만들어내는 데에 쓰이는 HTML, CSS, 자바스크립트 등의 언어들을 활용해 만들어집니다.
            이번에 선보이는 무제 작품들은 Laboratory Occupied의 주된 테마인 반복성과 임의성을 대표적으로 보여주고 있습니다. 일정하게 배열된 직사각형이 임의적으로, 또한 반복적으로 배치된 이 작품들은 모두 하나의 큰 틀을 공유하고 있지만, 작은 변수들, 즉 파라미터의 미세한 조정을 통해 서로 다른 인상을 만들어냅니다.
            특히 각 직사각형의 가로변과 세로변 길이는 싯가 전시 전체의 주제와도 밀접한 연관이 있습니다. 작품 속 모든 직사각형의 가로, 세로변 길이는 피보나치 수열의 일부로써 상정되는데, 이는 싯가의 가격 결정 알고리즘과 동일합니다. 다시 말해 싯가에서 판매하는 개별 작품 속에서도 판매행위예술로써 싯가의 주제가 연역될 수 있는 것입니다.`,
  },
];
